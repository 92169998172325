import React from "react";

export default function ImageList({ images, setCurrentImage }) {
  return (
    <aside className='imageList'>
      {images.map((img) => {
        return (
          <img
            className='imageSingle'
            key={img.id}
            src={img.url}
            alt={img.altText}
            title={img.title}
            onClick={() => setCurrentImage(img)}
          />
        );
      })}
    </aside>
  );
}
