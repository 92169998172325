import React from "react";

export default function Photo({ currentImage }) {
  return (
    <div className='currentImageDiv'>
      <img
        src={currentImage.url}
        alt={currentImage.altText}
        className='currentImage'
      />
    </div>
  );
}
