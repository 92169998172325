export default [
  {
    id: "19955904d5",
    url: "https://hungntgrb-blog.s3-ap-southeast-1.amazonaws.com/art-ai/bradp.png",
    altText: "Brad Pitt low-poly",
    title: "Brad Pitt low-poly",
  },
  {
    id: "1a78e2ca12",
    url: "https://hungntgrb-blog.s3-ap-southeast-1.amazonaws.com/art-ai/catlowpoly.png",
    altText: "Cat low-poly",
    title: "Cat low-poly",
  },
  {
    id: "323cb29c0c",
    url: "https://hungntgrb-blog.s3-ap-southeast-1.amazonaws.com/art-ai/lionlowpoly.png",
    altText: "Lion low-poly",
    title: "Lion low-poly",
  },
  {
    id: "0badba255d",
    url: "https://hungntgrb-blog.s3-ap-southeast-1.amazonaws.com/art-ai/scarletjohanson.png",
    altText: "Scarlet Johanson",
    title: "Scarlet Johanson",
  },
  {
    id: "8f6d4b6601",
    url: "https://hungntgrb-blog.s3-ap-southeast-1.amazonaws.com/art-ai/belle.jpg",
    altText: "Belle",
    title: "Belle",
  },
];

// export default [
//   "https://picsum.photos/800/600/?random=1",
//   "https://picsum.photos/800/600/?random=2",
//   "https://picsum.photos/800/600/?random=3",
//   "https://picsum.photos/800/600/?random=4",
//   "https://picsum.photos/800/600/?random=5",
//   "https://picsum.photos/800/600/?random=6",
//   "https://picsum.photos/800/600/?random=7",
//   "https://picsum.photos/800/600/?random=8",
// ];
