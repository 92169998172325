import React, { useState } from "react";
import imageList from "./data/imagelist";

import Header from "./components/Header";
import Photo from "./components/Photo";
import ImageList from "./components/ImageList";
import Footer from "./components/Footer";

export default function App() {
  const [images, setImages] = useState(imageList);
  const [currentImage, setCurrentImage] = useState(imageList[0]);

  return (
    <>
      <Header />

      <main className='main'>
        <Photo currentImage={currentImage} />
        <ImageList images={images} setCurrentImage={setCurrentImage} />
      </main>

      <Footer />
    </>
  );
}
